<template>
   <footer class="footer-bg-wrapper" loading="lazy">
      <section class="footer">
        <div class="footer-top">
          <div class="footer-links">
            <nav class="navbar navbar-expand-sm">
              <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link" href="/terms-condition" target="_blank"> {{translatedLangData('terms-and-conditions','Terms and Conditions')}} </a></li>
                <li class="nav-item"><a class="nav-link" href="/responsiblegaming" target="_blank"> {{translatedLangData('responsible-gaming','Responsible Gaming')}} </a></li>
               
              </ul>
            </nav>
          </div>
          <div class="support-detail"><h2>{{translatedLangData('twenty-four-supp','24X7 Support')}}</h2></div>
          <div class="social-icons-box"></div>
        </div>
      </section>
      <div class="meassage-sec whatsup-btn-fixed" v-if="siteSettings && siteSettings?.business_type == 2">
            <a href="javascript:void(0);" @click="getParentWhatsappNumber" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal">
                <div class="whatsapp-icon-btn">
                    <img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp">
                </div>
            </a>
            <!-- <ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent> -->
      </div>
      <div class="footer-bottom">
        <div class="secure-logo">
          <div><img src="@/assets/images/ssl.png"></div>
          <div class="ml-2"><b>{{translatedLangData('hunder-per-safe','100% SAFE')}}</b>
            <div>{{translatedLangData('prote-connec-encry-data','Protected connection and encrypted data.')}}</div>
          </div>
        </div>
          <div class="d-inline-block">
            <button class="btn p-0"><img src="@/assets/images/18plus.png"></button>
            <a href="https://www.gamcare.org.uk/" target="_blank">
              <img src="@/assets/images/gamecare.png">
            </a>
            <a href="https://www.gamblingtherapy.org/" target="_blank">
              <img src="@/assets/images/gt.png">
            </a>
          </div>
        </div>
         <p class="copyright">
            © {{translatedLangData('copy-right-reserved','Copyright 2024. All Rights Reserved.')}} Powered by {{$store.getters.siteSettings?.domain_name}}.
         </p>
         <!--Whatsup Deposit Modal -->
          <div class="modal fade whatsup-modal-wrapper" id="whatsup-depositModal" tabindex="-1" aria-labelledby="whatsupModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Customer Support</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body whatsup-modal-information">
                              <div class="whatsaap-support-btn-list" v-if="whatsappNumbers && whatsappNumbers.length > 0">
                                <div class="support-call-now" v-for="(data, index) in whatsappNumbers" :key="index">
                                    <a :href="'https://wa.me/' + data.whatsapp_number" target="_blank">
                                      <div class="support-whatsapp-icon"><img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp"></div>
                                      <span class="btn thm-btn">{{ data.category_name }}</span>
                                    </a>      
                                </div>
                              </div>
                              <div v-else-if="siteSettings && (siteSettings.social_link?.whatsappno1 || siteSettings.social_link?.whatsappno2)">
                              <template v-for="number in [siteSettings.social_link?.whatsappno1, siteSettings.social_link?.whatsappno2]">
                                  <a v-if="number" 
                                      :key="number" 
                                      :href="'https://wa.me/' + number" 
                                      target="_blank" 
                                      class="btn thm-btn call-now">
                                      <img src="@/assets/images/whatsapp-icon.webp" alt="whatsapp">{{ number }}
                                  </a>
                              </template>
                          </div>
                          <div v-else>
                              <p>{{translatedLangData('no-whatsapp-avail','No WhatsApp numbers available.')}}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <!--Whatsup Deposit Modal -->
        <div class="v-social-icon" v-if="siteSettings">
        <ul>
            <li>
                <a type="button" href="#install-apk-model" data-bs-toggle="modal">
                    <img src="@/assets/images/download-apk.svg" alt="">
                </a>
            </li>

            <li v-if="siteSettings?.business_type == 2 && siteSettings.social_link?.telegram_link">
                <a :href="siteSettings.social_link?.telegram_link" target="_blank">
                    <img src="@/assets/images/telegram.webp" alt="">
                </a>
            </li>

            <li v-if="siteSettings?.business_type == 2 && siteSettings.social_link?.whatsappno1">
                <a :href="'//wa.me/'+siteSettings.social_link?.whatsappno1">
                    <img src="@/assets/images/whatsapp-share.webp" alt="">
                </a>
            </li>

            <li v-if="siteSettings?.business_type == 2 && siteSettings.social_link?.instagram_link">
                <a :href="siteSettings.social_link?.instagram_link" target="_blank">
                    <img src="@/assets/images/instagram.webp" alt="">
                </a>
            </li>
           
            
        </ul>
    </div>
      </footer>
    <DownloadApkModal></DownloadApkModal>

</template>

<script>
import ChatComponent from '@/modules/chat/views/ChatComponent.vue';
import DownloadApkModal from "@/shared/components/modal/DownloadApkModal.vue";

export default {
  name: "Footer",
  inject: ['translatedLangData'],
  components: {
    ChatComponent,
    DownloadApkModal
  },
  computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
  },
  data() {
        return {
            whatsappNumbers:[],
        }
  },
  methods: {
    checkIsLogin() {
      return this.$store.getters.isAuthenticated;
    },
    async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }

                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();

                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
  },
};
</script>

<style scoped>
.footer-list-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>